<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <Fieldset id="couponinfo" :title="$t('Basic Information')">
        <b-form-group :label="$t('Name of bundled program')+`:`" label-cols-md="12">
          <validation-provider
            #default="{ errors }"
            name="Name of bundled program"
            rules="required"
          >
            <b-form-input
              v-model="model.name"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('Name of bundled program')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col md="4">
            <b-form-group :label="$t('Date from')+`:`" label-cols-md="12">
              <flat-pickr
                v-model="model.date_from"
                class="form-control"
                :config="{
                  enableTime: true,
                  minDate: 'today',
                  dateFormat: 'Y-m-d H:i:ss',
                }"
                placeholder="Choose a date"
              />
              <validation-provider
                #default="{ errors }"
                name="date from"
                rules="required"
              >
                <input v-model="model.date_from" type="hidden">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Date to')+`:`" label-cols-md="12">
              <flat-pickr
                v-model="model.date_to"
                class="form-control"
                :config="{
                  enableTime: true,
                  minDate: model.date_from,
                  dateFormat: 'Y-m-d H:i:ss',
                }"
                placeholder="Choose a date"
              />
              <validation-provider
                #default="{ errors }"
                name="date to"
                rules="required"
              >
                <input v-model="model.date_to" type="hidden">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              :label="$t('Active')+`:`"
              label-cols-md="12"
            >
              <b-form-checkbox
                v-model="model.is_active"
                checked="true"
                class="custom-control-success d-flex align-items-center"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group :label="$t('Limited product bundles')+`:`" label-cols-md="12">
          <validation-provider
            #default="{ errors }"
            name="Limited product bundles"
            rules="required"
          >
            <!-- <div class="d-flex align-items-center">
              <b-form-radio
                v-model="model.type"
                :value="0"
              >
                {{ $t('No requirement') }}
              </b-form-radio>
              <b-form-radio
                v-model="model.type"
                :value="1"
                class="ml-3"
              >
                {{ $t('Number of Bundle deals per Customer') }}
              </b-form-radio>
            </div> -->
            <b-form-input
              v-model="model.quantity"
              type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <div>{{ $t('Maximum number of shocking deal products each customer is allowed to buy with each order') }}</div>
          </validation-provider>
        </b-form-group>
      </Fieldset>
      <Fieldset id="bundleProduct" :title="$t('Main products')">
        <div class="sum-title">
          {{ $t('The maximum amount of each customer can be purchased is 100 main products in the same purchase program with a shock deal') }}
        </div>
        <div class="d-flex align-items-center justify-content-between mt-1">
          <b-button
            v-b-modal.modal-byproduct
            variant="primary"
            type="button"
          >
            {{ $t('Add product') }}
          </b-button>
        </div>
        <PopupEditProduct v-if="modelByProduct.id_promotion" v-model="modelByProduct" />
        <div v-if="modelByProduct.list_product && modelByProduct.list_product.length > 0">
          <CustomTable
            :items="modelByProduct.list_product"
            :per_page="1000"
            :paging="false"
            :fields="fields"
            custom-link="product/edit"
            :add_new_button="false"
            :sort_button="false"
          />
        </div>
      </Fieldset>
      <Fieldset id="promotionProduct" :title="$t('Bundled products')">
        <div class="sum-title">
          {{ $t('Buyers can enjoy promotional price products if they buy any major promotional products') }}
        </div>
        <div class="text-left">
          <b-button
            v-b-modal.modal-xl
            variant="primary"
            type="button"
            class="mt-1"
          >
            {{ $t('Add product') }}
          </b-button>
        </div>
        <PopupEditByProduct v-if="model.id_promotion" v-model="model" :syndata="modelByProduct.list_product" />
        <!-- Advance -->
        <div class="advance-block mb-2">
          <b-row>
            <b-col
              lg="3"
              class="d-flex flex-column justify-content-center"
            >
              {{ $t('Batch setting') }}
              <div><strong>{{ model.selected.length }}</strong>&nbsp;{{ $t('selected products') }}</div>
            </b-col>
            <b-col style="max-width: 125px;">
              <b-form-group
                :label="$t('Discount amount')"
              >
                <b-input-group
                  append="%"
                  class="mx-auto"
                >
                  <b-form-input
                    v-model="limitOn.percent"
                    type="number"
                    min="1"
                    max="100"
                    class="text-right"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :label="$t('Number of promotional products')"
                label-for="basicInput"
                class="limit-form-group"
              >
                <b-form-select
                  v-model="limitOn.promotion"
                  :options="limitProductsOptions"
                />
                <b-form-input
                  v-model="limitOn.promotionQuantity"
                  type="number"
                  min="1"
                  :placeholder="`${ $t('Minimum at') } 1`"
                  :disabled="!limitOn.promotion"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :label="$t('Limited products per person')"
                label-for="basicInput"
                class="limit-form-group"
              >
                <b-form-select
                  v-model="limitOn.person"
                  :options="limitProductsOptions"
                />
                <b-form-input
                  v-model="limitOn.personQuantity"
                  type="number"
                  min="1"
                  :placeholder="`${ $t('Minimum at') } 1`"
                  :disabled="!limitOn.person"
                />
              </b-form-group>
            </b-col>

            <b-col
              lg="2"
              md="6"
              class="d-flex flex-column justify-content-center"
              style="gap: 8px;"
            >
              <b-button
                variant="gradient-success"
                size="sm"
                pill
                class="w-100"
                @click="advanceApply"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Apply') }}</span>
              </b-button>

              <b-button
                variant="gradient-danger"
                size="sm"
                pill
                class="w-100"
                :disabled="model.selected.length === 0"
                @click="advanceDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Delete') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <PromotionItems v-model="model" />
      </Fieldset>
      <BCard v-if="model.status == 'Upcoming'">
        <b-button
          variant="info"
          type="button"
          @click.prevent="validationForm"
        >
          {{ $t('Update') }}
        </b-button>
      </BCard>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BFormSelect,
  // BPagination,
  // BAvatar,
  BCard,
} from 'bootstrap-vue'
// import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
// import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PopupEditProduct from './components/PopupEditProduct.vue'
import PopupEditByProduct from './components/PopupEditByProduct.vue'
import PromotionItems from './components/PromotionItems.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    flatPickr,
    // vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    PopupEditProduct,
    PopupEditByProduct,
    BInputGroup,
    BFormSelect,
    // VueGoodTable,
    // BPagination,
    // BAvatar,
    BCard,
    PromotionItems,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      percent: null,
      required,
      filter: null,
      searchTerm: '',
      all_product_old: [],
      model: {
        name: null,
        date_from: null,
        date_to: null,
        is_active: true,
        list_product: [],
        list_product_old: [],
        type: 0,
        quantity: null,
        discount_money: null,
        currency_id: null,
        selectedAll: false,
        selected: [],
        validCheckAll: 0,
        id_promotion: null,
        warehouse_bundled: [],
      },
      modelByProduct: {
        name: null,
        date_from: null,
        date_to: null,
        is_active: true,
        list_product: [],
        list_product_old: [],
        type: 0,
        quantity: null,
        discount_money: null,
        currency_id: null,
        id_promotion: null,
      },
      currencyOptionsDefault: [
        // {
        //   iso_code: 'VNĐ',
        //   name: 'Vietnam đồng',
        //   img: '',
        // },
        // {
        //   iso_code: 'USD',
        //   name: 'US Dollar',
        //   img: '',
        // },
      ],
      fields: [
        // { key: 'checkbox', label: '#', sortable: true },
        { key: 'no', label: 'No', sortable: true },
        { key: 'avatar', label: 'Avatar', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
        { key: 'price', label: 'Price', sortable: true },
        { key: 'check_active', label: 'Display on/off', sortable: false },
        { key: 'more', label: 'Tool', sortable: false },
      ],
      rows: [],
      pageLength: 10,
      promotionProductsSelected: 0,
      promotionProductsSelectedCount: 0,
      limitOn: {
        promotion: null,
        person: null,
        promotionQuantity: null,
        personQuantity: null,
        percent: null,
      },
      limitProductsOptions: [
        {
          value: null,
          text: 'Không giới hạn',
        },
        {
          value: 'limit',
          text: 'Giới hạn',
        },
      ],
      columns: [
        {
          label: 'Product',
          field: 'fullName',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Discount Price',
          field: 'priceDiscount',
        },
        {
          label: 'Inventory',
          field: 'inventory',
        },
        {
          label: 'Shipping',
          field: 'Shipping',
        },
        {
          label: 'Number of products',
          field: 'productsOnSale',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  watch: {
    'model.percent'() {
      this.model.percent = this.handlePercent(this.model.percent)
    },
    'modelByProduct.list_product'() {
      const temp = []
      this.model.list_product.map(val => {
        const findData = this.modelByProduct.list_product.find(x => x.id_number === val.id)
        if (!findData) {
          temp.push(val)
        }
      })
      this.model.list_product = temp
    },
  },
  mounted() {
    this.loadcurrency()
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/v2/bundled/${this.$route.params.id}?site_id=${siteId}`,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.model = Object.assign(this.model, res.data.data)
          this.model.date_from = `${res.data.data.date_from}`
          this.model.date_to = `${res.data.data.date_to}`
          this.model.is_active = Boolean(res.data.data.is_active === 1)
          this.model.id_promotion = res.data.data.id_number
          this.model.quantity = res.data.data.limit_buy
          this.model.warehouse_bundled = res.data.data.warehouse_bundled
          this.model.status = res.data.data.status
          this.all_product_old = res.data.data.product

          // this.modelByProduct.list_product_old = JSON.parse(res.data.data.json_product_main)
          const prodMain = JSON.parse(res.data.data.json_product_main)
          const tempProdMain = []
          prodMain.map(x => {
            this.model.warehouse_bundled.map(y => {
              if (x.product_id === y.product_id && y.is_main === 1) {
                tempProdMain.push({
                  id: y.id,
                  product_id: x.product_id,
                  is_active: x.is_active,
                })
              }
            })
          })
          this.modelByProduct.list_product_old = tempProdMain
          this.modelByProduct.id_promotion = res.data.data.id_number

          const temp = []
          res.data.data.product.map(val => {
            const findData = this.modelByProduct.list_product_old.find(x => Number(x.product_id) === Number(val.id))
            if (!findData) {
              temp.push(val)
            }
          })
          this.model.list_product_old = temp
        }
      }
    },
    checkDate() {
      let bloon = true
      if (this.model.date_from != null && this.model.date_to != null) {
        bloon = false
      }
      return bloon
    },
    async validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: this.model.name,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              limit_buy: this.model.quantity,
            }
            if (this.model.date_from !== null) {
              params.date_from = this.model.date_from
            }
            if (this.model.date_to !== null) {
              params.date_to = this.model.date_to
            }

            const listIdProduct = []
            this.modelByProduct.list_product.map(val => {
              listIdProduct.push({
                id: val.id_bundle,
                product_id: val.id,
                is_active: val.check_active ? 1 : 0,
              })
            })

            const listPromotionWarehouse = []
            let numEr = 0
            this.model.list_product.map((val, index) => {
              const findData = this.model.list_product[index]
              findData.children.map((val2, index2) => {
                const temp = this.model.list_product[index].children[index2]
                if (val2.valid.status === true) {
                  listPromotionWarehouse.push({
                    id: val2.id_warehouse_promotion,
                    product_id: val.id,
                    warehouse_id: val2.id,
                    limit_quantity: val2.is_limit_warehouse === 'limit' ? val2.limit_warehouse : 0,
                    percent: val2.percent,
                    is_active: val2.status ? 1 : 0,
                  })
                  if (Number(temp.percent) === 0 || temp.percent > 100 || temp.percent < 0) {
                    temp.valid.ok_percent = 'error'
                    numEr += 1
                    temp.valid.show = true
                    temp.valid.status = false
                    temp.status = false
                  } else {
                    temp.valid.ok_percent = ''
                  }
                } else {
                  numEr += 1
                }
                return true
              })
              return true
            })

            if (numEr !== 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('Invalid product list'),
                },
              })
              return false
            }
            params.json_product_main = JSON.stringify(listIdProduct)
            params.json_product_child = JSON.stringify(listPromotionWarehouse)

            if (listPromotionWarehouse.length === 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('No products selected yet'),
                },
              })
              return false
            }
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/v2/bundled/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                const delList = []
                this.model.list_product_old.map(val => {
                  const findProdDel2 = listPromotionWarehouse.find(x => x.product_id === val.id)
                  if (!findProdDel2) {
                    delList.push(val.id)
                  }
                  return true
                })

                this.modelByProduct.list_product_old.map(val => {
                  const findProdDel = listIdProduct.find(x => x.product_id === val.product_id)
                  if (!findProdDel) {
                    delList.push(val.product_id)
                  }
                  return true
                })

                const delParams = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  product_id: JSON.stringify(delList),
                  bundled_id: this.model.id_promotion,
                }
                await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/v2/bundled/delete_product_from_promotion`,
                  delParams,
                )
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Bundle success',
                  },
                })
                this.$router.push('/bundle/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadcurrency() {
      try {
        let list = []
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_CLIENT_API_URL}/platform_currency`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            list = res.data.data.items
            const resLang = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/currencies?site_id=${
                JSON.parse(localStorage.getItem('siteID')).id
              }`,
            )
            if (resLang.status === 200) {
              if (resLang.data.status) {
                this.currencyOptionsDefault = resLang.data.data.items.map(val => {
                  const find = list.find(x => x.iso_code === val.code)
                  if (find) {
                    find.id = val.id
                    return find
                  }
                  return false
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlePercent() {
      if (this.model.percent < 0) {
        this.model.percent = 0
      }
      if (this.model.percent > 100) {
        this.model.percent = 100
      }
    },
    handlerDelete(id) {
      this.model.list_product.splice(this.model.list_product.findIndex(val => val.id === id), 1)
    },
    selectionChanged() {
      this.promotionProductsSelected = this.$refs['promotion-products'].selectedRows
      this.promotionProductsSelectedCount = this.$refs['promotion-products'].selectedRows.length
    },
    advanceApply() {
      if (this.model.selected.length > 0) {
        this.model.selected.map(val => {
          const findData = this.model.list_product.find(x => x.id === val)
          if (findData) {
            findData.limitProducts = this.limitOn.personQuantity
            findData.isLimitProduct = this.limitOn.person
            findData.children.map((val2, index) => {
              findData.children[index].percent = this.limitOn.percent
              findData.children[index].discount = findData.children[index].price - ((findData.children[index].price / 100) * this.limitOn.percent)
              findData.children[index].limit_warehouse = this.limitOn.promotionQuantity
              findData.children[index].is_limit_warehouse = this.limitOn.promotion
              return true
            })
          }
          return true
        })
      } else {
        this.model.list_product.map((val, index) => {
          const findData = this.model.list_product[index]
          findData.limitProducts = this.limitOn.personQuantity
          findData.isLimitProduct = this.limitOn.person
          findData.children.map((val2, index2) => {
            findData.children[index2].percent = this.limitOn.percent
            findData.children[index2].discount = findData.children[index2].price - ((findData.children[index2].price / 100) * this.limitOn.percent)
            findData.children[index2].limit_warehouse = this.limitOn.promotionQuantity
            findData.children[index2].is_limit_warehouse = this.limitOn.promotion
            return true
          })
          return true
        })
      }
      this.model.validCheckAll = Math.random()
    },
    advanceDelete() {
      const temp = JSON.parse(JSON.stringify(this.model.selected))
      temp.map(id => {
        this.model.list_product.splice(this.model.list_product.find(val => val.id === id), 1)
        return true
      })
      this.model.selected = []
      this.model.selectedAll = false
    },
    convertCurrencyToPercent(currency) {
      // eslint-disable-next-line no-param-reassign
      currency.discount_percent = Math.round(100 - ((currency.discount_price * 100) / currency.price))
    },
    convertPercentToCurrency(percent) {
      // eslint-disable-next-line no-param-reassign
      percent.discount_price = percent.price - ((percent.discount_percent * percent.price) / 100)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
  }
  td {
    vertical-align: middle;
  }
  .input-group {
    width: 165px;
    max-width: 150px;
  }
  .form-group {
    width: 165px;
  }
  .limit-form-group {
    max-width: 180px;

    label {
      max-width: 130px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 60px;
    }
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
  .avatar-name {
    display: flex;
    align-items: center;
    min-width: 200px;

    .name {
      max-width: 180px;
    }
  }
}
.advance-block {
  background: #F6F6F6;
  padding: 1rem;

  .form-group {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
  .form-group {
    height: 100%;

    > div {
      margin-top: auto;
    }
  }
  .limit-form-group {
    label {
      max-width: 130px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 60px;
    }
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}
</style>
