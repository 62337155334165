<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    id="modal-xl"
    ok-title="Save"
    centered
    size="xl"
    :title="$t('Add product to list bundle')"
    no-close-on-backdrop
    no-close-on-esc
    @ok="handleUpdate"
    @cancel="handleUpdate"
    @close="handleUpdate"
  >
    <b-row>
      <b-col lg="3">
        <ProductCategorySort
          :list="catItems"
          @handlerChangeCategory="handlerChangeCategory"
        />
      </b-col>
      <b-col lg="9">
        <b-row>
          <b-col lg="6">
            <div class="d-flex">
              <b-input-group class="input-group-merge w-100 round">
                <!-- <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend> -->
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
              </b-input-group>
              <b-button class="ml-2 text-nowrap" @click="handlerSearch">
                {{ $t('Search') }}
              </b-button>
              <b-button class="ml-2 text-nowrap" @click="resetFilter">
                {{ $t('Reset') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
        <ProductList v-model="value" :list="items" />
      </b-col>
    </b-row>
    <!-- <ProductList v-model="value" :list="items" /> -->
  </b-modal>
</template>
<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable array-callback-return */
import {
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductCategorySort from './ProductCategorySort.vue'
import ProductList from './ProductList.vue'

export default {
  components: {
    BInputGroup,
    BRow,
    BCol,
    ProductCategorySort,
    ProductList,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    syndata: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      items: [],
      jsonitems: '[]',
      catItems: [],
      productListTemp: [],
      searchQuery: null,
      category_id: null,
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'syndata'() {
      const temp = []
      const itemtemps = JSON.parse(this.jsonitems)
      itemtemps.map(val => {
        const findData = this.syndata.find(x => x.id_number === val.id)
        if (!findData) {
          temp.push(val)
        }
      })
      this.items = temp
    },
  },
  async mounted() {
    await this.loadList()
    this.loadListCategory()
    if (this.value.list_product_old.length > 0) await this.loadListProduct()
  },
  methods: {
    handlerChangeCategory(data) {
      this.category_id = data.item.id
      this.loadList(this.category_id)
    },
    handlerSearch() {
      this.loadList()
    },
    resetFilter() {
      this.category_id = null
      this.searchQuery = null
      this.loadList()
    },
    handleUpdate() {
      // const ids = this.value.list_product.map(o => o.id_number)
      // const filtered = this.value.list_product.filter(({ id_number }, index) => !ids.includes(id_number, index + 1))
      // this.value.list_product = filtered
    },
    async loadList(categoryID = null) {
      try {
        let params = ''
        // if (this.value.date_from_to !== null) {
        //   if (this.value.date_from_to.split(' to ').length > 1) {
        //     const dateFromTo = this.value.date_from_to.split(' to ')
        //     params = `&date_from=${dateFromTo[0]}&date_to=${dateFromTo[1]}`
        //   }
        // }
        if (categoryID) {
          params += `&category_id=${categoryID}`
        }
        if (this.searchQuery) {
          params += `&name=${this.searchQuery}`
        }
        const listProductId = []
        this.value.list_product_old.map(x => listProductId.push(x.id))
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}${params}&is_not_bundled=1&sort[]=id,desc&bundled_find=${this.value.id_promotion}&list_not_product_id=${JSON.stringify(listProductId)}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = []
            this.productListTemp = []
            res.data.data.items.map(val => {
              const warehouse = []
              // eslint-disable-next-line camelcase
              val.warehouse.map(val2 => {
                let temp = []
                try {
                  temp = JSON.parse(val2.name_attribute || '[]')
                } catch (error) { return false }
                let nameAttr = ''
                temp.map(val3 => {
                  nameAttr += `${val3.value}-`
                })
                nameAttr = nameAttr.slice(0, -1)
                const valid = {
                  show: false,
                  status: true,
                  warning: false,
                  ok_name: '',
                  ok_inventory: '',
                  ok_price: '',
                  ok_discount: '',
                  ok_percent: '',
                  ok_limit_warehouse: '',
                  ok_is_limit_warehouse: '',
                  ok_status: '',
                }
                if (val2.quantity === 0) {
                  valid.ok_inventory = 'warning'
                  valid.warning = true
                }
                const itemWh = {
                  id: val2.id,
                  id_warehouse_promotion: 0,
                  name: nameAttr,
                  id_prod: val.id_number,
                  inventory: val2.quantity,
                  price: val2.price,
                  discount: val2.price,
                  percent: 0,
                  limit_warehouse: 0,
                  is_limit_warehouse: null,
                  status: !valid.warning,
                  valid,
                }
                const findWh = this.value.warehouse_bundled.find(x => x.warehouse_id === val2.id)
                if (findWh) {
                  itemWh.id_warehouse_promotion = findWh.id
                  itemWh.percent = findWh.percent
                  itemWh.status = findWh.is_active === 1
                  itemWh.limit_warehouse = findWh.limit_quantity
                  itemWh.discount = val2.price - ((val2.price / 100) * findWh.percent)
                  itemWh.is_limit_warehouse = findWh.limit_quantity === 0 ? null : 'limit'
                }
                warehouse.push(itemWh)
                return true
              })
              const item = {
                mode: 'span',
                avatar: val.avatar,
                created_at: val.created_at,
                id: val.id_number,
                is_active: val.is_active,
                name: val.name,
                position: val.position,
                id_promotion: val.bundled_id,
                // price: val.price,
                sku: val.sku,
                category_slug: val.category_slug,
                list_id_promotion: val.list_id_promotion,
                list_promotion: val.list_promotion,
                isLimitProduct: null,
                limitProducts: 0,
                isLimitOnPerson: null,
                limit_products_on_person: null,
                discount_price: val.price,
                discount_percent: 0,
                label: val.name,
                children: warehouse,
                warehouse: val.warehouse,
              }
              this.items.push(item)
              this.jsonitems = JSON.stringify(this.items)
              this.productListTemp.push(item)
              // const findData = this.value.list_product_old.find(x => x.id === val.id_number)
              // if (findData) {
              //   this.value.list_product.push(item)
              // }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadListProduct() {
      try {
        const listProductId = []
        this.value.list_product_old.map(x => listProductId.push(x.id))
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&is_not_bundled=1&sort[]=id,desc&bundled_find=${this.value.id_promotion}&list_product_id=${JSON.stringify(listProductId)}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // this.items = []
            // this.productListTemp = []
            res.data.data.items.map(val => {
              const warehouse = []
              // eslint-disable-next-line camelcase
              val.warehouse.map(val2 => {
                let temp = []
                try {
                  temp = JSON.parse(val2.name_attribute || '[]')
                } catch (error) { return false }
                let nameAttr = ''
                temp.map(val3 => {
                  nameAttr += `${val3.value}-`
                })
                nameAttr = nameAttr.slice(0, -1)
                const valid = {
                  show: false,
                  status: true,
                  warning: false,
                  ok_name: '',
                  ok_inventory: '',
                  ok_price: '',
                  ok_discount: '',
                  ok_percent: '',
                  ok_limit_warehouse: '',
                  ok_is_limit_warehouse: '',
                  ok_status: '',
                }
                if (val2.quantity === 0) {
                  valid.ok_inventory = 'warning'
                  valid.warning = true
                }
                const itemWh = {
                  id: val2.id,
                  id_warehouse_promotion: 0,
                  name: nameAttr,
                  id_prod: val.id_number,
                  inventory: val2.quantity,
                  price: val2.price,
                  discount: val2.price,
                  percent: 0,
                  limit_warehouse: 0,
                  is_limit_warehouse: null,
                  status: !valid.warning,
                  valid,
                }
                const findWh = this.value.warehouse_bundled.find(x => x.warehouse_id === val2.id)
                if (findWh) {
                  itemWh.id_warehouse_promotion = findWh.id
                  itemWh.percent = findWh.percent
                  itemWh.status = findWh.is_active === 1
                  itemWh.limit_warehouse = findWh.limit_quantity
                  itemWh.discount = val2.price - ((val2.price / 100) * findWh.percent)
                  itemWh.is_limit_warehouse = findWh.limit_quantity === 0 ? null : 'limit'
                }
                warehouse.push(itemWh)
                return true
              })
              const item = {
                mode: 'span',
                avatar: val.avatar,
                created_at: val.created_at,
                id: val.id_number,
                is_active: val.is_active,
                name: val.name,
                position: val.position,
                id_promotion: val.bundled_id,
                // price: val.price,
                sku: val.sku,
                category_slug: val.category_slug,
                list_id_promotion: val.list_id_promotion,
                list_promotion: val.list_promotion,
                isLimitProduct: null,
                limitProducts: 0,
                isLimitOnPerson: null,
                limit_products_on_person: null,
                discount_price: val.price,
                discount_percent: 0,
                label: val.name,
                children: warehouse,
                warehouse: val.warehouse,
              }
              this.items.unshift(item)
              this.jsonitems = JSON.stringify(this.items)
              this.productListTemp.unshift(item)
              const findData = this.value.list_product_old.find(x => x.id === val.id_number)
              if (findData) {
                this.value.list_product.push(item)
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadListCategory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.catItems = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
